<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="legend-box">
          <div class="legend-item">
            <span class="color-box color-5"></span> Viel über dem Durchschnitt
          </div>
          <div class="legend-item">
            <span class="color-box color-4"></span> Über dem Durchschnitt
          </div>
          <div class="legend-item">
            <span class="color-box color-3"></span> Durchschnitt
          </div>
          <div class="legend-item">
            <span class="color-box color-2"></span> Unter dem Durchschnitt
          </div>
          <div class="legend-item">
            <span class="color-box color-1"></span> Viel unter dem Durchschnitt
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LegendBox'
};
</script>

<style scoped>
.legend-box {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-item:last-child {
  margin-bottom: 0;
}

.color-box {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
}
.color-0 {
  background-color: #A5A5A5;
}
.color-1 {
  background-color: #57BC89;
}
.color-2 {
  background-color: #9DBE6B;
}
.color-3 {
  background-color: #FDD666;
}
.color-4 {
  background-color: #F3A86C;
}
.color-5 {
  background-color: #E57C72;
}
</style>
