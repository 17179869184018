<template>
  <div class="view map" style="display: flex; flex-direction: column; margin-top: env(safe-area-inset-top); height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));">
    <!-- Top bar navigation -->
    <header class="app navigation" style="flex: 0; background-color: var(--msag-color); padding: 10px;">
      <v-container>
        <v-row justify="space-between" style="align-items: center; padding-bottom: 5px;">
          <div style="margin-right:10px;">
            <v-row justify="space-between" style="align-items: center;">
              <v-col style="flex:0;">
                <div style="background-color:white; padding: 10px; border-radius: 10px; height: 50px; width: 50px;">
                  <img src="@/assets/Maklersprechstunde-Logo.png" alt="Square Image" style="width: 30px; height: 30px;"/>
                </div>
              </v-col>
              <v-col style="flex:1; padding: 0px; margin: 0px;">
                <h1 style="color: white; text-align: left;">Portalpreise.com</h1>
              </v-col>

          <a style="margin: 5px 10px;" class="addButton" href="https://maklersprechstunde.com/portalpreise-neuer-eintrag" target="_blank" rel="noopener noreferrer">
            Preis&nbsp;Hinzufügen&nbsp;+
          </a>
              <div v-if="$vuetify.breakpoint.smAndDown" style="flex:1;">
                <v-row justify="flex-end" style="align-items: center; margin: 5px 10px;">
                  <v-btn @click="toggleFilters" style="padding: 0px 8px 0px 4px; background-color: white; border-radius: 10px;">
                    <v-icon>{{ showFilters ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                    FILTER
                  </v-btn>
                </v-row>
              </div>
            </v-row>
          </div>
          <div style="flex:1; max-width:700px; padding:0px 10px; padding-left:10px;">
            <v-row v-if="!$vuetify.breakpoint.smAndDown || showFilters" justify="space-between" style="align-items: center; width: 100%;" dark>
              <v-col style="min-width:100px;">
                <v-select
                    label="Portal"
                    :items="filter.portal.items"
                    v-model="filter.portal.selected"
                    @change="updateView()"
                    :disabled="mapIsLoading"
                    hide-details
                    dark
                    :menu-props="{ maxHeight: '500px' }"
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                  label="Objekte"
                  :items="filter.objectsPerMonth.items"
                  v-model="filter.objectsPerMonth.selected"
                  @change="updateView()"
                  :disabled="mapIsLoading"
                  hide-details
                  dark
                />
              </v-col>
              <v-col style="min-width:155px;">
                <v-select
                  label="Vertragszusätze"
                  :items="filter.contract_additions"
                  v-model="filter.contract_additions.selected"
                  @change="updateView()"
                  :disabled="mapIsLoading"
                  hide-details
                  dark
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                  label="Tätigkeit"
                  :items="filter.profession.items"
                  v-model="filter.profession.selected"
                  @change="updateView()"
                  :disabled="mapIsLoading"
                  hide-details
                  dark
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                    label="Vertragsjahr"
                    :items="filter.year.items"
                    v-model="filter.year.selected"
                    @change="updateView()"
                    :disabled="mapIsLoading"
                    hide-details
                    dark
                    :menu-props="{ maxHeight: '500px' }"
                />
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-container>

    <!-- Side bar rendering -->
    <SideBar
      v-if="selection != null"
      :obj="selection"
      @update="updateView"
      @markAllZipcodes="markAllZipcodes"
      @close-sidebar="resetSelected"
    />

    </header>

    <!-- Map rendering -->
    <div style="flex: 1;">
      <div class="view map-loadingscreen" v-if="mapIsLoading">
        <div class="centeredcontent">
          <v-container>
            <v-row justify="center">
              <v-col>
                <v-icon size="calc(2rem + 2vw + 2vh)" class="spinning-icon"
                  >mdi-loading</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <MglMap
        id="mapboxElem"
        :mapboxGl="map"
        :accessToken="mapboxAttr.accessToken"
        :mapStyle="mapboxAttr.mapStyle"
        :zoom.sync="mapboxAttr.zoom"
        :center.sync="mapboxAttr.center"
        @load="onMapLoaded"
        :minZoom="4.5"
        :maxZoom="12"
      >
        <div style="position: fixed; z-index: 1000; right: 0;">
          <v-container>
            <v-row>
              <v-col>
                <div
                    class="nav-btn"
                    @click="mapboxAttr.zoom += 1"
                >
                  <span class="nav-btn-correction">
                    +
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div
                    class="nav-btn"
                    @click="mapboxAttr.zoom -= 1"
                >
                  <span class="nav-btn-correction">
                    -
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </MglMap>
    </div>
    <footer style="position:fixed; flex: 0; bottom: 0; left: 0; right: 0; z-index: 1000;">
      <LegendBox class="legend-container" style="margin-left: 0;"/>

      <div style="margin-top: auto; background-color: #FFF; color:#666;">
        <v-container>
          <p style="margin-bottom: 0; font-size: 14px;">Ⓒ Makler Service AG {{ new Date().getFullYear() }} | <a href="/impressum/">Impressum</a> | <a href="
/datenschutzerklaerung/">Datenschutz</a></p>
        </v-container>
      </div>
    </footer>
  </div>
</template>
<script>

/* eslint-disable */
import Mapbox from "mapbox-gl";
import {MglMap} from "vue-mapbox";
import {getFromServer} from "@/scripts/serverCalls";
import SideBar from "@/components/SideBar.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import axios from "axios";
import LegendBox from "@/components/LegendBox.vue";

export default {
  name: "MapView",

  components: {
    MglMap,
    SideBar,
    ConfirmDialog,
    LegendBox
  },
  watch: {
    "mapboxAttr.statusView": function (newVal) {
      if (!newVal && this.map.getSource(this.mapboxAttr.mapboxSrc.zipcode.id)) {
        this.map.removeLayer(this.mapboxLayers.zipcodeFill.id);
        this.map.removeLayer(this.mapboxLayers.zipcodeBorder.id);
        this.map.removeLayer(this.mapboxLayers.zipcodeFillMarked.id);

        this.map.removeSource(this.mapboxAttr.mapboxSrc.zipcode.id);
      } else {
        this.map.addSource(this.mapboxAttr.mapboxSrc.zipcode.id, {
          type: "geojson",
          data: this.mapboxAttr.mapboxSrc.data,
          generateId: true,
        });
        this.map.addLayer({
          id: this.mapboxLayers.zipcodeFill.id,
          type: this.mapboxLayers.zipcodeFill.type,
          paint: this.mapboxLayers.zipcodeFill.paint,
          source: this.mapboxAttr.mapboxSrc.zipcode.id,
        });
        this.map.addLayer({
          id: this.mapboxLayers.zipcodeBorder.id,
          type: this.mapboxLayers.zipcodeBorder.type,
          paint: this.mapboxLayers.zipcodeBorder.paint,
          source: this.mapboxAttr.mapboxSrc.zipcode.id,
        });
        this.map.addLayer({
          id: this.mapboxLayers.zipcodeFillMarked.id,
          type: this.mapboxLayers.zipcodeFillMarked.type,
          paint: this.mapboxLayers.zipcodeFillMarked.paint,
          source: this.mapboxAttr.mapboxSrc.zipcode.id,
        });
      }
    },
  },
  data() {
    return {
      showFilters: false, // Track the visibility of the filters
      filter: {
        portal: {
          items: [
            { text: "Alle", value: null },
            { text: "Immoscout24", value: "Immoscout" },
            { text: "Immowelt", value: "Immowelt" },
            { text: "Immonet", value: "Immonet" },
            { text: "Kleinanzeigen Immobilien", value: "Kleinanzeigen" },
            { text: "Immobilie1", value: "Immobilie1" },
            { text: "Willhaben", value: "Willhaben" },
            { text: "properti", value: "properti" },
          ],
          selected: "",
        },
        profession: {
          items: ["Alle", "Bauträger", "Immobilienmakler", "Immobilienverwalter"],
          selected: "",
        },
        objectsPerMonth: {
          items: [
            { text: "Alle", value: null },
            { text: "0-15", value: "0-15" },
            { text: "15-30", value: "15-30" },
            { text: "30-60", value: "30-60" },
            { text: "60-100", value: "60-100" },
            { text: "100+", value: "100+" },
          ],
          selected: "",
        },
        year: {
          items: this.generateYears(),
          selected: ""
        },
        contract_additions: [
        { text: "Alle", value: null },
        { text: "Ja", value: "true" },
        { text: "Nein", value: "false" },
        ],
        selected: "",
      },
      mapIsLoading: true,
      hover: {
        id: null,
        transparent: 0,
      }, // Current hovered item
      hoverLock: true,
      selection: null, // Current selected item
      marked: [], // Curren marked items
      mapboxAttr: {
        statusView: true,
        zoom: 0,
        center: [0, 0],
        accessToken: process.env.VUE_APP_MAPBOX_KEY,
        mapStyle: "mapbox://styles/etifakler/clgmgxgu300bs01qu6ypdfjdf",
        markers: [],
        mapboxSrc: {
          zipcode: {
            id: "zipcode",
            data: null,
          },
        },
      },
      mapboxLayers: {
        zipcodeFill: {
          id: "zipcode-fill",
          type: "fill",
          paint: {
            "fill-color": {
              property: "status",
              stops: [
                [0, "#A5A5A5"],
                [1, "#57BC89"],
                [2, "#9DBE6B"],
                [3, "#FDD666"],
                [4, "#F3A86C"],
                [5, "#E57C72"],
                [6, "#C84E43"],
              ],
            },
            "fill-opacity": [
              "case",
              ["==", ["feature-state", "transparent"], 0],
              0.65,
              ["==", ["feature-state", "transparent"], 1],
              0.9,
              ["==", ["feature-state", "transparent"], 2],
              0.9,
              0.65,
            ],
          },
        },
        zipcodeBorder: {
          id: "zipcode-border",
          type: "line",
          paint: {
            "line-color": {
              property: "status",
              stops: [
                [0, "#848484"],
                [1, "#499E6B"],
                [2, "#7EAE4C"],
                [3, "#C8B032"],
                [4, "#C38F45"],
                [5, "#BB6047"],
                [6, "#A33B30"],
              ],
            },
            "line-width": 1,
          },
        },
        zipcodeFillMarked: {
          id: "zipcode-fill-marked",
          type: "fill",
          paint: {
            "fill-color": [
              "case",
              ["boolean", ["feature-state", "marked"], false],
              "#4FF",
              "#FFF",
            ],
            "fill-opacity": [
              "case",
              ["boolean", ["feature-state", "marked"], false],
              0.35,
              0,
            ],
          },
        },
      },
    };
  },
  methods: {
    generateYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      years.push("Alle");
      for (let i = 0; i < 7; i++) {
        years.push((currentYear - i).toString());
      }
      years.push("vor " + (currentYear - 6).toString());
      return years;
    },
    toggleFilters() {
      this.showFilters = !this.showFilters; // Toggle the visibility of the filters
    },
    constructQuery(start = "?"){
      let queryParts = [];
      if (this.filter.portal.selected) queryParts.push(`portal=${encodeURIComponent(this.filter.portal.selected)}`);
      if (this.filter.profession.selected && this.filter.profession.selected !== "Alle") queryParts.push(`profession=${encodeURIComponent(this.filter.profession.selected)}`);
      if (this.filter.objectsPerMonth.selected) queryParts.push(`objectspermonth=${encodeURIComponent(this.filter.objectsPerMonth.selected)}`);
      if (this.filter.year.selected && this.filter.year.selected !== "Alle") queryParts.push(`year=${encodeURIComponent(this.filter.year.selected)}`);
      if (this.filter.contract_additions.selected) queryParts.push(`contract_additions=${encodeURIComponent(this.filter.contract_additions.selected)}`);
      return queryParts.length > 0 ? `${start}${queryParts.join("&")}` : "";
    },
    updateView() {
      this.resetMarked();
      this.updateMap();
      this.onZipcodeClick();
    },
    /**
     * Updates map source
     */
    updateMap() {
      this.mapIsLoading = true;
      setTimeout(() => {  // Delay for accuracy
        getFromServer(`map/geo/all${this.constructQuery()}`, (res) => {
          this.mapboxAttr.mapboxSrc.data = res.data;
          this.map
            .getSource(this.mapboxAttr.mapboxSrc.zipcode.id)
            .setData(this.mapboxAttr.mapboxSrc.data);
          this.mapIsLoading = false;
        });
      }, 100);
    },
    /**
     * Updates map markers
     */
    async updateMarkers() {
      await getFromServer("map/prices/all", (res) => {
        this.mapboxAttr.markers = res.data;
      });

      for (const item of this.mapboxAttr.markers) {
        try {
          const response = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              item.address
            )}.json`,
            {
              params: {
                access_token:
                  "pk.eyJ1IjoiZXRpZmFrbGVyIiwiYSI6ImNsaWtvdnIzdjAyMnUzZm12OXF0eDE2aGwifQ.pbXfhnvfBUPg1oagZhiSAw",
                limit: 1,
              },
            }
          );
          if (response.data.features.length > 0) {
            const location = response.data.features[0].center;

            let color = this.getColorForStatus(item.status);
            let size = this.getSizeForObjects(item.objects_per_month);
            const el = this.createMarkerElement(color, size);

            new mapboxgl.Marker(el).setLngLat(location).addTo(this.map);
          }
        } catch (error) {
          console.error("Geocoding error:", error);
        }
      }
    },
    getColorForStatus(status) {
      switch (status) {
        case 1:
          return "#146CCB";
        case 2:
          return "#4B419B";
        case 3:
          return "#734B8F";
        case 4:
          return "#B04B8F";
        case 5:
          return "#E37662";
        case 6:
          return "#E1B437";
        default:
          return "black";
      }
    },
    getSizeForObjects(objects_per_month) {
      if (objects_per_month < 5) {
        return 10;
      } else if (objects_per_month < 15) {
        return 15;
      } else if (objects_per_month < 30) {
        return 20;
      } else if (objects_per_month < 60) {
        return 25;
      } else {
        return 30;
      }
    },
    createMarkerElement(color, size) {
      const el = document.createElement("div");
      el.className = "marker";
      el.style.backgroundColor = color;
      el.style.width = `${size}px`;
      el.style.height = `${size}px`;
      el.style.borderRadius = "50%";
      return el;
    },
    resetSelected() {
      this.map.setFeatureState(
        {
          source: this.mapboxAttr.mapboxSrc.zipcode.id,
          id: this.selection.id,
        },
        { transparent: 0 }
      );

      this.selection = null;
    },
    /**
     * Removes all marked items
     */
    resetMarked() {
      this.marked.forEach((el) => {
        concole.log(el.id);
        this.map.setFeatureState(
          {
            source: this.mapboxAttr.mapboxSrc.zipcode.id,
            id: el.id,
          },
          {
            marked: false,
          }
        );
      });
      this.marked = [];
      this.confirmDelMarkedDialog = false;
    },
    /**
     * On map load
     * @param {*} e Map item
     */
    onMapLoaded(e) {
      this.map = e.map;
      /* this.updateMarkers(); Disabled due to performance */
    },
    /**
     * On Zipcode click requests details of zipcode and inserts it into selection
     * @param {*} e Mapbox event
     */
    onZipcodeClick(e) {
      const featureId = e?.features[0]?.id;
      const zipCode = e?.features[0]?.properties?.plz || this.selection?.zipcode;
      if (
        featureId == null
        || zipCode == null
        || (zipCode === this.selection?.zipcode)
      ) return;

      const status = e?.features[0]?.properties?.status

      getFromServer(
        `map/details?zipcode=${zipCode}${this.constructQuery('&')}`,
        (res) => {
          if(this.selection != null) {
            this.map.setFeatureState(
              {
                source: this.mapboxAttr.mapboxSrc.zipcode.id,
                id: this.selection.id,
              },
              { transparent: 0 }
            );
          }
          res.data.status = status;
          this.selection = res.data;
          this.selection.id = featureId;
          this.map.setFeatureState(
            {
              source: this.mapboxAttr.mapboxSrc.zipcode.id,
              id: this.selection.id,
            },
            { transparent: 2 }
          );
          this.hover.transparent = 2;
        }
      );
    },
    /**
     * On Zipcode doubledlick marks a zipcode and runs check if addContract is disabled
     * @param {*} e Mapbox event
     */
    onZipcodeDblClick(e) {
      if (this.selection !== null) {
        // Waits for selection to not be NULL
        var isMarked = this.map.getFeatureState(
          {
            source: this.mapboxAttr.mapboxSrc.zipcode.id,
            id: e.features[0].id,
          },
          null,
          "marked"
        ).marked;

        if (isMarked) {
          this.map.setFeatureState(
            {
              source: this.mapboxAttr.mapboxSrc.zipcode.id,
              id: e.features[0].id,
            },
            {
              marked: false,
            }
          );
          this.map.setFeatureState(
            { source: this.mapboxAttr.mapboxSrc.zipcode.id, id: this.hover.id },
            { transparent: 0 }
          );
          var index = this.marked.findIndex(
            (elem) => elem.id === e.features[0].id
          );
          this.marked.splice(index, 1);
        } else {
          this.map.setFeatureState(
            {
              source: this.mapboxAttr.mapboxSrc.zipcode.id,
              id: e.features[0].id,
            },
            {
              marked: true,
            }
          );
          this.marked.push({
            id: e.features[0].id,
            zipcode: e.features[0].properties.plz,
            partner_id: e.features[0].properties.partner_id,
            status: e.features[0].properties.status,
          });
        }
        this.updateIsAddContractDisabled(); // Check if Add Contract is disabled
      }
    },
    async markAllZipcodes(zipcodes) {
      await this.resetMarked();
      this.map
        .querySourceFeatures(this.mapboxAttr.mapboxSrc.zipcode.id)
        .forEach((feature) => {
          // Check if the feature's zipcode matches the desired zipcode
          if (zipcodes.includes(feature.properties.plz)) {
            // Feature is found, so we set its state
            this.map.setFeatureState(
              {
                source: this.mapboxAttr.mapboxSrc.zipcode.id,
                id: feature.id,
              },
              { marked: true }
            );
            this.marked.push({
              id: feature.id,
              zipcode: feature.properties.plz,
              partner_id: feature.properties.partner_id,
              status: feature.properties.status,
            });
            this.updateIsAddContractDisabled();
          }
        });
    },
  },
  /**
   * On lifecycle create, initializes Mapbox and click events
   */
  async mounted() {
    document.title = `Portalpreise - Map`;

    this.map = null;
    getFromServer(`map/geo/all${this.constructQuery()}`, async (res) => {
      // Waiting for map to resolve
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.map !== null) {
            clearInterval(interval);
            resolve();
          }
        }, 50);
      });

      this.mapboxAttr.mapboxSrc.data = res.data;
      this.map.addSource(this.mapboxAttr.mapboxSrc.zipcode.id, {
        type: "geojson",
        data: this.mapboxAttr.mapboxSrc.data,
        generateId: true,
      });
      this.map.addLayer({
        id: this.mapboxLayers.zipcodeFill.id,
        type: this.mapboxLayers.zipcodeFill.type,
        paint: this.mapboxLayers.zipcodeFill.paint,
        source: this.mapboxAttr.mapboxSrc.zipcode.id,
      });
      this.map.addLayer({
        id: this.mapboxLayers.zipcodeBorder.id,
        type: this.mapboxLayers.zipcodeBorder.type,
        paint: this.mapboxLayers.zipcodeBorder.paint,
        source: this.mapboxAttr.mapboxSrc.zipcode.id,
      });
      this.map.addLayer({
        id: this.mapboxLayers.zipcodeFillMarked.id,
        type: this.mapboxLayers.zipcodeFillMarked.type,
        paint: this.mapboxLayers.zipcodeFillMarked.paint,
        source: this.mapboxAttr.mapboxSrc.zipcode.id,
      });
      this.map.addControl(
          new MapboxGeocoder({
            accessToken: this.mapboxAttr.accessToken,
            mapboxgl: Mapbox,
            marker: {
              color: "#e8423e",
            },
          }),
          "top-left"
      );
      this.map.on("mouseenter", "zipcode-fill", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });
      this.map.on("mouseleave", "zipcode-fill", () => {
        this.map.getCanvas().style.cursor = "";
      });
      this.map.on("mousemove", "zipcode-fill", (e) => {
        if (this.hoverLock) {
          if (this.hover.id !== null && this.hover.transparent !== 2) {
            this.map.setFeatureState(
                {
                  source: this.mapboxAttr.mapboxSrc.zipcode.id,
                  id: this.hover.id,
                },
                {transparent: 0}
            );
          }
          const feature = this.map.getFeatureState({
            source: this.mapboxAttr.mapboxSrc.zipcode.id,
            id: e.features[0].id,
          });
          this.hover.id = e.features[0].id;
          this.hover.transparent = feature.transparent;
          if (this.hover.transparent !== 2) {
            this.map.setFeatureState(
                {
                  source: this.mapboxAttr.mapboxSrc.zipcode.id,
                  id: this.hover.id,
                },
                {transparent: 1}
            );
          }
        }
        this.hoverLock = false;
        setTimeout(() => {
          this.hoverLock = true;
        }, 35);
      });
      // Handle mouseout event to reset the hover effect
      this.map.on("mouseout", "zipcode-fill", (e) => {
        if (this.hover.id !== null && this.hover.transparent !== 2) {
          this.map.setFeatureState(
              {
                source: this.mapboxAttr.mapboxSrc.zipcode.id,
                id: this.hover.id,
              },
              {transparent: 0}
          );
          this.hover.id = null;
          this.hover.transparent = 0;
        }
      });
      this.map.on("click", "zipcode-fill", (e) => {
        this.onZipcodeClick(e);
      });

      this.mapIsLoading = false;
      this.mapboxAttr.zoom = -10;
    });
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
  },
};
</script>
<style lang="scss">
.map-loadingscreen {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.415);
  backdrop-filter: blur(2px);
}
.centeredcontent {
  text-align: center;
}

a.addButton {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    color: var(--msag-color);
    text-decoration: none;
    flex:0;
    padding:5px 10px;
    font-size: 14px;
}

a.addButtonBig {
  background-color: var(--msag-color);
  border-radius: 10px;
  text-align: center;
  color: white !important;
  text-decoration: none;
  flex:0;
  padding: 5px 10px;
  font-size: 14px;
}

a:hover.addButton {
    background-color: #EEE;
}

.legend-container {
  z-index: 100;
  max-width: 350px;
}

.nav-btn {
  width: 50px;
  height: 50px;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #FFF;
  user-select: none;
  font-size: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #CCC;
}
.nav-btn:hover {
  background-color: #EEE;
}

.nav-btn-correction {
  margin-top: -6px;
}
</style>
