<template>
<div class="heat-meter-container">
  <div class="metric-pointer" :style="`margin-left: ${getLeadingOffset()}%;`">
    <v-icon size="60px" color="black">mdi-chevron-down</v-icon>
  </div>
  <div class="metric"/>
</div>
</template>

<script>
export default {
  name: "HeatMeter",
  props: ["obj"],
  methods: {
    getLeadingOffset() {
      if (this.obj && typeof this.obj.status === 'number' && this.obj.status >= 0 && this.obj.status <= 5) {
        return Math.round(((5 - this.obj.status) / 5) * 100);
      }
    }
  }
}
</script>


<style scoped lang="scss">
.heat-meter-container {
  position: relative;
  width: 100%;
  height: 64px;
}
.metric {
  position: absolute;
  bottom: 0px;
  border-radius: 20px;
  width: 100%;
  height: 20px;
  background: linear-gradient(
          to left,
          #57BC89,
          #9DBE6B,
          #FDD666,
          #F3A86C,
          #E57C72,
  );
}
.metric-pointer {
  position: absolute;
  top: 0px;
}
</style>